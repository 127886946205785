import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const StoryPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Headphone Treats Story | Quirk Peddler & Champion of Striking Kooks"
        ogDescription="Headphone Treats is an Atlanta, GA-based record label founded by producer, engineer and recording artist, Jimmy Ether. We like eclectic sounds, infectious melody, clever lyrics and people with hearts installed in roughly the right places."
        url="https://headphonetreats.com"
      />
      <PageWrapper color="burgandy">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h1>The Headphone Treats Story</h1>
              <h3>by Jimmy Ether</h3>
            
              <h2>Hearing Headphone Treats</h2>
              <p>
                I am obsessed with recorded music. As a kid, I would sit in the basement with my D-battery guzzling boombox as cassettes of stray assemblage would squawk and huff around the concrete walls. Strange details in recordings beyond the songs often grabbed me. A singer’s inhale before the first lyric or shuffling musicians at the end of a song would fixate me. A tape warble, an amp buzz, or someone speaking over an intercom seemed a hidden message only I could find. Then I discovered you could hear this sonic confectionary far better with headphones, and even more of it buried deep in the mix. A laugh. A mumble. The squeak of a kick pedal. A telephone. A clammed note that generated an unexpected moment of dissonance.
              </p>
              <p>
                I obsessed over those subtle details because they helped me find a way behind the scenes. They put me in the room where the music was happening and provided access to the record making process. Idiosyncrasies made the music less premeditated, more a convergence of time and intention, more natural and fragile.
              </p>
              <p>
                A couple decades later when I started my career as a recording engineer, I pled with the bands I recorded to leave those little noises in their records. I didn’t want to deny the listener that feeling of access I’d had as a kid. I’d pull up songs like “The Good Mr. Square” by The Pretty Things and point out the freaky stereo cough you can only hear in headphones. I’d play “Strawberry Fields” for them. There is a precise spot where Geoff Emerick edited together two recording takes, and the matching of two different tempos and tunings unintentionally creates a feeling of four walls melting around you. These things are treasures of happenstance, and we must protect them.
              </p>
              <p>
                I called these moments “headphone treats”. I used the term often enough around my own bandmates that my drummer suggested I use it for my fledgling record label. And so in 2003, the Headphone Treats imprint was born.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="white">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Stalking Label Visionaries</h2>
              <p>
                I started to realize just how a record label could work as a centralizing force for musicians and fans. I don’t mean that I understood or cared about the music industry’s business side. You could argue I still don’t. What I did notice was that if a label released three records I liked, it wasn’t a big gamble to take a chance on a fourth. Not only did labels gravitate toward certain qualities in their artists, they cultivated a kind of tribal mystique. If you were a fan, it was like being in a secret club of people who shared an understanding about life that most others didn't. The only membership fee was a love for the music.
              </p>
              <p>
                I obsessed over the origin stories of pioneers behind these labels as much as the artists on them. Record store owners like Geoff Travis, Danny Beard, and Jac Holzman, who formed Rough Trade, DB, and Elektra. Musicians taking charge like Greg Ginn (SST), Jello Biafra (Alternative Tentacles), and Mac McCaughan and Laura Ballance (Merge). Writer and producer team Kenneth Gamble and Leon Huff established Philadelphia International to focus their vision of sound. Berry Gordy imposed his keen sense of sound and unusual production procedures on Motown. Exuberant fans of local scenes, like Ahmet Ertegun (Atlantic), Chris Lombardi (Matador), and Slim Moon and Tinuviel Sampson (Kill Rock Stars), helped their artists make a national impact. The label owners all had a strong connection to the music they were hearing around them, music other labels weren’t offering. And they were each compelled to build something great from within those musical communities.
              </p>
              <p>
                Good labels are about vision. The people behind them have the unique ability to recognize talent in the rough and nurture it. They develop their own process and methods for exposing those artists to a wider audience. They build a collective energy around their roster and catalog. They get the musicians and the fans excited about a bigger idea than just one band ... just one album ... just one single. They shine light on a developing history. They carve out their own niche.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="charcole">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Carving a Niche of Striking Kooks</h2>
              <p>
                My taste in music is broad and eclectic. I like artists from almost every genre and era. But I tend to be most attracted to those who play with lyrical abstraction, produce a rough-edged sound and perform as if jello-wrestling a raw nerve. My vision for Headphone Treats includes an array of music styles. Noise rock. Experimental pop. Sludgy metal. Underground hip hop. Beat tapes. Avant-garde jazz. Neo soul. Alt-country and roots rock. But over all else, quirk is the essential element and common theme in what Headphone Treats aritsts do. Not for the sake of being weird, but because that’s who they are. In the same way records seem more accessible through those noisy headphone treats, the exposed eccentricities of people highlight their most admirable and charming qualities. I like performers who are tight at being freaks, and I will do my best to seek them out and bring them to you. Headphone Treats is here to bring you striking kooks.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Got something to say? Need something? Wanna chat?</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default StoryPage
